<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            <span v-if="email">Edit Email: {{email.EmailName}}</span>
            <template v-slot:actions>
                <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                    mdi-close
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        Manage Email
                        <v-icon>mdi-email</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item value="tab-1">
                        <v-card flat class="white py-4">
                            <v-card-text>
                                <ManageEmail :win="win" :email="email" :editMood="true"></ManageEmail>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import ManageEmail from './ManageEmail';

    export default {
        name: "EmailData",
        props: ['win'],
        components: {
            ManageEmail
        },
        data() {
            return {
                tab: null,
                email: null
            }
        },
        mounted() {
            this.email = this.win.meta.email;
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            }
        }

    }
</script>

<style scoped>

</style>
